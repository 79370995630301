<script setup lang="ts">
import OrganisationInput from '@libero/data-types/input/OrganisationInput.vue';
import { useForm } from '@libero/hooks/useForm';
import { FilterOperator } from '@libero/types/Filter';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  value?: string;
  onUpdate: (value?: string) => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const form = useForm(
  computed(() => {
    const [type = FilterOperator.Equal, organisation] = props.value?.split('|') || [];

    return {
      type: type as FilterOperator,
      organisation,
    };
  }),
);

const handleClear = () => {
  form.values.type = FilterOperator.Equal;
  form.clearValues(['organisation']);
  props.onUpdate(undefined);
};

const handleSubmit = () => {
  if ([FilterOperator.Equal, FilterOperator.NotEqual].includes(form.values.type)) {
    return props.onUpdate(`${form.values.type}|${form.values.organisation}`);
  }

  return props.onUpdate(`${form.values.type}`);
};

const isDisabled = computed(
  () =>
    [FilterOperator.Equal, FilterOperator.NotEqual].includes(form.values.type) &&
    !form.values.organisation,
);
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <Stack>
      <Stack :gap="1">
        <Select
          size="sm"
          :options="[
            { label: t('data-type.condition-options.equal'), value: FilterOperator.Equal },
            { label: t('data-type.condition-options.not-equal'), value: FilterOperator.NotEqual },
            { label: t('data-type.condition-options.empty'), value: FilterOperator.Empty },
            { label: t('data-type.condition-options.not-empty'), value: FilterOperator.NotEmpty },
          ]"
          v-bind="form.register('type')"
        />

        <OrganisationInput
          v-if="
            form.values.type === FilterOperator.Equal ||
            form.values.type === FilterOperator.NotEqual
          "
          size="sm"
          v-bind="form.register('organisation')"
        />
      </Stack>

      <Cluster alignItems="center">
        <Button type="submit" :isDisabled="isDisabled" size="sm">
          {{ t('apply') }}
        </Button>

        <Button color="secondary" :isDisabled="!value" appearance="link" :onClick="handleClear">
          {{ t('clear') }}
        </Button>
      </Cluster>
    </Stack>
  </Form>
</template>
