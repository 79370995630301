import { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { PaginationQuery, PaginationResource } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { DimCalendar, StoreDimCalendarRequest } from './types';

const dimCalendar = jsonApi('dim-calendar');

const index = (query?: PaginationQuery): Promise<PaginationResource<DimCalendar>> => {
  return dimCalendar.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<DimCalendar> => {
  return dimCalendar.get(id.toString());
};

const store = (data: StoreDimCalendarRequest): Promise<DimCalendar> => {
  return dimCalendar.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StoreDimCalendarRequest): Promise<DimCalendar> => {
    return dimCalendar.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<void> => {
  return dimCalendar.delete(id.toString());
};

const activity = (id: string | number): Promise<Activity[]> => {
  return dimCalendar.get(`${id}/activity`);
};

export const dimCalendarApi = {
  name: 'dim-calendar',
  index,
  show,
  store,
  update,
  destroy,
  activity,
  view: generateResourceViewApi('dim-calendar'),
  scout: generateResourceScoutApi<DimCalendar>('dim-calendar', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
