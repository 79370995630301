import {
  ClipboardDocumentListIcon,
  ExclamationTriangleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import { FunctionalComponent } from 'vue';

export const getTicketTypeIconColor = (key: string): Color => {
  switch (key) {
    case 'report':
      return Color.warningBase;
    case 'project':
      return Color.primary600;
    case 'question':
    default:
      return Color.gray800;
  }
};

export const getTicketTypeIcon = (key: string): FunctionalComponent => {
  switch (key) {
    case 'report':
      return ExclamationTriangleIcon;
    case 'project':
      return ClipboardDocumentListIcon;
    case 'question':
    default:
      return QuestionMarkCircleIcon;
  }
};
