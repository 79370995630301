import { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { Role, StoreRoleRequest } from './types';

const role = jsonApi('role');

const index = (query?: PaginationQuery): Promise<PaginationResource<Role>> => {
  return role.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<Role> => {
  return role.get(id.toString());
};

const store = (data: StoreRoleRequest): Promise<Role> => {
  return role.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StoreRoleRequest): Promise<Role> => {
    return role.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<unknown> => {
  return role.delete(id.toString());
};

const favorite = (id: string | number): Promise<unknown> => {
  return role.post(`${id}/favorite`);
};

const activity = (id: string | number): Promise<Activity[]> => {
  return role.get(`${id}/activity`);
};

export const roleApi = {
  name: 'role',
  index,
  show,
  store,
  update,
  destroy,
  favorite,
  activity,
  view: generateResourceViewApi('role'),
  scout: generateResourceScoutApi<Role>('role', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
