<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import type { AttributeType } from '@libero/api-client/attribute-type/types';
import { useCollectionFilter } from '@libero/hooks/useCollectionFilter';
import { useForm } from '@libero/hooks/useForm';
import { useInput } from '@libero/hooks/useInput';
import { FilterOperator } from '@libero/types/Filter';
import Button from '@libero/ui-framework/Button/Button.vue';
import CheckboxGroup from '@libero/ui-framework/Checkbox/CheckboxGroup.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import ConditionalWrapper from '@libero/ui-framework/ConditionalWrapper/ConditionalWrapper.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import Input from '@libero/ui-framework/Input/Input.vue';
import Scrollable from '@libero/ui-framework/Scrollable/Scrollable.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  value?: string | null;
  attributeType?: AttributeType;
  enumerationValues?: Record<string, string>;
  onUpdate: (value?: string) => void;
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  attributeType: undefined,
  enumerationValues: undefined,
});

const { t } = useI18n();
const [searchTerm, registerSearchTerm] = useInput('search-term');

const form = useForm(
  computed(() => {
    const [type = FilterOperator.Equal, values] = props.value?.split('|') || [];

    return {
      type: type as FilterOperator,
      value: values?.split(';') || [],
    };
  }),
);

const options = computed(() =>
  props.enumerationValues
    ? Object.entries(props.enumerationValues).map(([key, value]) => ({
        value: key,
        label: value,
      }))
    : props.attributeType?.enumeration_values?.map((value) => ({
        value,
        label: value,
      })) || [],
);

const filteredOptions = useCollectionFilter(options, searchTerm, ['label']);

const handleClear = () => {
  form.values.type = FilterOperator.Equal;
  form.clearValues(['value']);
  props.onUpdate(undefined);
};

const handleSubmit = () => {
  if (form.values.type === FilterOperator.Equal || form.values.type === FilterOperator.NotEqual) {
    return props.onUpdate(`${form.values.type}|${form.values.value.join(';')}`);
  }

  return props.onUpdate(`${form.values.type}`);
};

const hasInput = computed(() =>
  [FilterOperator.Equal, FilterOperator.NotEqual].includes(form.values.type),
);

const isDisabled = computed(() => hasInput.value && !form.values.value?.length);
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <Stack>
      <Stack :gap="0.75">
        <Select
          size="sm"
          :options="[
            { label: t('data-type.condition-options.equal'), value: FilterOperator.Equal },
            { label: t('data-type.condition-options.not-equal'), value: FilterOperator.NotEqual },
            { label: t('data-type.condition-options.empty'), value: FilterOperator.Empty },
            { label: t('data-type.condition-options.not-empty'), value: FilterOperator.NotEmpty },
          ]"
          v-bind="form.register('type')"
        />

        <Stack v-if="hasInput" :gap="0.5">
          <Input size="sm" :placeholder="t('search.search')" v-bind="registerSearchTerm()">
            <template #icon>
              <MagnifyingGlassIcon />
            </template>
          </Input>

          <ConditionalWrapper
            :component="Scrollable"
            :isWrapped="filteredOptions.length > 6"
            :props="{ minHeight: '11rem' }"
          >
            <CheckboxGroup size="sm" :options="filteredOptions" v-bind="form.register('value')" />
          </ConditionalWrapper>
        </Stack>
      </Stack>

      <Cluster alignItems="center">
        <Button type="submit" :isDisabled="isDisabled" size="sm">
          {{ t('apply') }}
        </Button>

        <Button color="secondary" :isDisabled="!value" appearance="link" :onClick="handleClear">
          {{ t('clear') }}
        </Button>
      </Cluster>
    </Stack>
  </Form>
</template>
