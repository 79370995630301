import { jsonApi } from '@libero/utilities/api-client';

import { Note, StoreNoteRequest, UpdateNoteRequest } from './types';

export interface NoteRelationApi {
  name: string;
  parentName: string;
  parentId: number | string;
  store: (data: StoreNoteRequest) => Promise<Note>;
  update: (id: string | number) => (data: UpdateNoteRequest) => Promise<Note>;
  destroy: (id: string | number) => Promise<void>;
}

export const noteRelationApi =
  (relationName: string) =>
  (relationId: string | number): NoteRelationApi => {
    const noteRelation = jsonApi(`${relationName}/${relationId}/note`);

    const store = (data: StoreNoteRequest): Promise<Note> => {
      return noteRelation.post(undefined, data);
    };

    const update =
      (id: string | number) =>
      (data: UpdateNoteRequest): Promise<Note> => {
        return noteRelation.patch(id.toString(), data);
      };

    const destroy = (id: string | number): Promise<void> => {
      return noteRelation.delete(id.toString());
    };

    return {
      name: `${relationName}-${relationId}-note`,
      parentName: relationName,
      parentId: relationId,
      store,
      update,
      destroy,
    };
  };
