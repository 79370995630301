import type { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { Organisation, StoreOrganisationRequest } from './types';

const organisation = jsonApi('organisation');

const index = (query?: PaginationQuery): Promise<PaginationResource<Organisation>> => {
  return organisation.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<Organisation> => {
  return organisation.get(id.toString());
};

const store = (data: StoreOrganisationRequest): Promise<Organisation> => {
  return organisation.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StoreOrganisationRequest): Promise<Organisation> => {
    return organisation.put(id.toString(), data);
  };

const favorite = (id: string | number): Promise<unknown> => {
  return organisation.post(`${id}/favorite`);
};

const activity = (id: string | number): Promise<Activity[]> => {
  return organisation.get(`${id}/activity`);
};

export const organisationApi = {
  name: 'organisation',
  index,
  show,
  store,
  update,
  favorite,
  activity,
  view: generateResourceViewApi('organisation'),
  scout: generateResourceScoutApi<Organisation>('organisation', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
