import type { Column } from '@libero/api-client/column/types';
import { PaginationQuery } from '@libero/api-client/types/pagination';
import { DataType } from '@libero/types/DataType';
import { FilterType } from '@libero/types/Filter';
import type { Component } from 'vue';

import BooleanFilter from './BooleanFilter.vue';
import DateFilter from './DateFilter.vue';
import DecimalFilter from './DecimalFilter.vue';
import EnumerationFilter from './EnumerationFilter.vue';
import IntegerFilter from './IntegerFilter.vue';
import LeadTimeFilter from './LeadTimeFilter.vue';
import OrganisationFilter from './OrganisationFilter.vue';
import PowerScheduleFilter from './PowerScheduleFilter.vue';
import StringFilter from './StringFilter.vue';
import TimeFilter from './TimeFilter.vue';
import YearFilter from './YearFilter.vue';

export const FilterDataTypes: Record<DataType, Component> = {
  [DataType.String]: StringFilter,
  [DataType.Float]: DecimalFilter,
  [DataType.Date]: DateFilter,
  [DataType.Boolean]: BooleanFilter,
  [DataType.Year]: YearFilter,
  [DataType.Decimal]: DecimalFilter,
  [DataType.Text]: StringFilter,
  [DataType.Integer]: IntegerFilter,
  [DataType.Time]: TimeFilter,
  [DataType.DateTime]: DateFilter,
  [DataType.Enumeration]: EnumerationFilter,
  [DataType.Geometry]: BooleanFilter,
  [DataType.Organisation]: OrganisationFilter,
  [DataType.Product]: EnumerationFilter,
  [DataType.DimCalendar]: EnumerationFilter,
  [DataType.PowerSchedule]: PowerScheduleFilter,
  [DataType.Money]: DecimalFilter,
  [DataType.URL]: StringFilter,
  [DataType.TicketTime]: DateFilter,
  [DataType.LeadTime]: LeadTimeFilter,
  [DataType.LeadTimeDiscount]: DecimalFilter,
  [DataType.LeadTimeStatus]: EnumerationFilter,
};

export const getFilterType = (column: Column): FilterType => {
  switch (column.data_type) {
    case DataType.String:
      return FilterType.String;
    case DataType.Float:
      return FilterType.Number;
    case DataType.Date:
      return FilterType.Date;
    case DataType.Boolean:
      return FilterType.Boolean;
    case DataType.Year:
      return FilterType.Year;
    case DataType.Decimal:
      return FilterType.Number;
    case DataType.Text:
      return FilterType.String;
    case DataType.Integer:
      return FilterType.Number;
    case DataType.Time:
      return FilterType.Date;
    case DataType.DateTime:
      return FilterType.Date;
    case DataType.Enumeration:
      return FilterType.Enumeration;
    case DataType.Geometry:
      return FilterType.Boolean;
    case DataType.Organisation:
      return FilterType.Organisation;
    case DataType.Product:
      return FilterType.Product;
    case DataType.DimCalendar:
      return FilterType.DimCalendar;
    case DataType.PowerSchedule:
      return FilterType.PowerSchedule;
    case DataType.Money:
      return FilterType.Money;
    case DataType.URL:
      return FilterType.String;
    case DataType.TicketTime:
      return FilterType.TicketTime;
    case DataType.LeadTime:
      return FilterType.LeadTime;
    case DataType.LeadTimeDiscount:
      return FilterType.LeadTimeDiscount;
    case DataType.LeadTimeStatus:
      return FilterType.LeadTimeStatus;
  }
};

export const isFilterActive = (params: PaginationQuery, column: Column): boolean => {
  const type = column.attribute_type_id
    ? (`a-${getFilterType(column)}` as FilterType)
    : getFilterType(column);

  const filters = params.filter?.[type];

  if (!filters) return false;

  return JSON.parse(filters).find((string: string) => string.startsWith(column.key));
};
