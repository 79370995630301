import { computed, Ref } from 'vue';

import { useThemeStore } from './store/useThemeStore';

interface UseGlobalFilter {
  globalFilter: Ref<{
    themes?: string;
  }>;
}

export const useGlobalFilter = (): UseGlobalFilter => {
  const themeStore = useThemeStore();

  const globalFilter = computed(() => ({
    themes: themeStore.activeThemes.join(',') || undefined,
  }));

  return {
    globalFilter,
  };
};
