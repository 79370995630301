<script setup lang="ts">
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { classNames } from '@libero/utilities/class-names';
import { InputNumber } from 'ant-design-vue';
import { isNumber, isString } from 'lodash';
import { ref } from 'vue';
import { ComponentPublicInstance } from 'vue';
import { onMounted, onUnmounted } from 'vue';

interface Props {
  id: string;
  name: string;
  value: number | string | null;
  min?: number;
  max?: number;
  precision?: number;
  maxPrecision?: number;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  minWidth?: string;
  width?: string;
  error?: string;
  textAlign?: 'end';
  disabled?: boolean;
  isDirty?: boolean;
  isStringMode?: boolean;
  onUpdate: (value: number | string | null) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  min: undefined,
  max: undefined,
  precision: undefined,
  maxPrecision: undefined,
  minWidth: undefined,
  width: undefined,
  error: undefined,
  textAlign: undefined,
  size: 'md',
  onMount: undefined,
  onUnmount: undefined,
});

const inputNumber = ref<ComponentPublicInstance<typeof InputNumber>>();

const handleBlur = () => {
  const value = isString(props.value) ? parseFloat(props.value) : props.value;
  const currentPrecision = value?.toString().split('.')[1]?.length || 0;

  if (props.maxPrecision && currentPrecision > props.maxPrecision) {
    props.onUpdate(value?.toFixed(props.maxPrecision) || null);
  }

  if (props.precision) {
    props.onUpdate(value?.toFixed(props.precision) || null);
  }
};

onMounted(() => {
  props.onMount?.(inputNumber.value?.focus, props.disabled);
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0">
    <InputNumber
      :id="id"
      ref="inputNumber"
      :name="name"
      :class="classNames({ size, textAlign, isDirty })"
      :value="isNumber(value) ? value : value || undefined"
      :min="min"
      :max="max"
      :disabled="disabled"
      :precision="precision"
      decimalSeparator=","
      :stringMode="isStringMode"
      :controls="false"
      :style="{ minWidth, width }"
      :onBlur="handleBlur"
      :onUpdate:value="onUpdate"
    >
      <template v-if="$slots.addonAfter" #addonAfter>
        <slot name="addonAfter" />
      </template>
    </InputNumber>

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/InputNumber/InputNumber.scss';
</style>
