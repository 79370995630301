<script setup lang="ts">
import type { AttributeType } from '@libero/api-client/attribute-type/types';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import InputNumber from '@libero/ui-framework/InputNumber/InputNumber.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';

interface Props {
  id: string;
  name: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value: string | number | null;
  attributeType?: AttributeType;
  isDirty?: boolean;
  onUpdate: (value: string | null) => void;
  onUnmount?: () => void;
}

const props = defineProps<Props>();

const handleUpdate = (value: number | string | null) => {
  switch (typeof value) {
    case 'string':
      return props.onUpdate(parseFloat(value).toFixed(2));

    case 'number':
      return props.onUpdate(value.toFixed(2));

    default:
      return props.onUpdate(null);
  }
};
</script>

<template>
  <InputGroup>
    <InputNumber
      :id="id"
      :name="name"
      :value="value"
      :size="size"
      :step="attributeType?.step"
      :precision="2"
      :max="attributeType?.max"
      :min="attributeType?.min || 0"
      minWidth="10rem"
      stringMode
      :isDirty="isDirty"
      :onUpdate="handleUpdate"
      :onUnmount="onUnmount"
    >
      <template v-if="attributeType?.unit_type" #addonAfter>
        <Typography size="sm" isDark>
          {{ attributeType.unit_type }}
        </Typography>
      </template>
    </InputNumber>
  </InputGroup>
</template>
