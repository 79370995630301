import { jsonApi } from '@libero/utilities/api-client';

import { StoreMediaRequest } from './types';

export interface MediaRelationApi {
  name: string;
  parentName: string;
  parentId: number | string;
  store(data: StoreMediaRequest): Promise<void>;
  destroy(id: string | number): Promise<void>;
}

export const mediaRelationApi =
  (relationName: string) =>
  (relationId: string | number): MediaRelationApi => {
    const mediaRelation = jsonApi(`${relationName}/${relationId}/media`);

    const store = (data: StoreMediaRequest): Promise<void> => {
      return mediaRelation.post(undefined, data);
    };

    const destroy = (id: string | number): Promise<void> => {
      return mediaRelation.delete(id.toString());
    };

    return {
      name: `${relationName}-${relationId}-media`,
      parentName: relationName,
      parentId: relationId,
      store,
      destroy,
    };
  };
