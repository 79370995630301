import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import { QueryFilter } from '@libero/types/Filter';
import { isString, mapValues } from 'lodash';

export const getStatusId = (key: string): number => {
  const { tenant } = useTenantStore();
  return tenant?.ticket_status_ids[key] || 0;
};

export const getStatusIds = (keys: string[]): string => {
  return keys.map(getStatusId).join(';');
};

export const parseFilters = (filters?: QueryFilter): Record<string, string[]> | undefined => {
  if (!filters) return undefined;

  return mapValues(filters, (value) => {
    if (!isString(value)) return value;

    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  });
};
