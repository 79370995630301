import Fuse from 'fuse.js';
import { get } from 'lodash';
import { computed, isRef, MaybeRef, Ref } from 'vue';

export const useCollectionFilter = <T>(
  optionsRef: MaybeRef<T[] | undefined>,
  searchTerm: Ref<string | null>,
  keys: string[],
  hideRef?: MaybeRef<boolean>,
): Ref<T[]> => {
  const options = computed(() => (isRef(optionsRef) ? optionsRef.value : optionsRef));
  const hide = computed(() => (isRef(hideRef) ? hideRef.value : hideRef));

  const fuse = computed(
    () => new Fuse(options.value || [], { keys, threshold: 0.1, distance: 500 }),
  );

  const filteredOptions = computed(() =>
    searchTerm.value
      ? fuse.value
          .search(searchTerm.value)
          .map((result) => result.item)
          .slice(0, 50)
      : (!hide.value && options.value) || [],
  );

  const sortedOptions = computed(() =>
    filteredOptions.value.sort((a, b) => {
      const first = get(a, keys[0]);
      const second = get(b, keys[0]);

      if (first === null && second === null) return 0;
      if (first === null) return 1;
      if (second === null) return -1;

      return first.toString().localeCompare(second.toString());
    }),
  );

  return sortedOptions;
};
