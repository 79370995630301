import { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { EntityType, StoreEntityTypeRequest } from './types';

const entityType = jsonApi('entity-type');

const index = (query?: PaginationQuery): Promise<PaginationResource<EntityType>> => {
  return entityType.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<EntityType> => {
  return entityType.get(id.toString());
};

const store = (data: StoreEntityTypeRequest): Promise<EntityType> => {
  return entityType.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StoreEntityTypeRequest): Promise<EntityType> => {
    return entityType.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<unknown> => {
  return entityType.delete(id.toString());
};

const activity = (id: string | number): Promise<Activity[]> => {
  return entityType.get(`${id}/activity`);
};

export const entityTypeApi = {
  name: 'entity-type',
  index,
  show,
  store,
  update,
  destroy,
  activity,
  view: generateResourceViewApi('entity-type'),
  scout: generateResourceScoutApi<EntityType>('entity-type', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
