// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const mergeOverwrite = (source: any, target: any): any => {
  if (typeof source !== 'object' || typeof target !== 'object') {
    return target || source;
  }

  const base = { ...source };

  Object.entries(target).forEach(([key, value]) => {
    if (typeof value === 'object') {
      base[key] = mergeOverwrite(base[key], value);
    } else {
      base[key] = value;
    }
  });

  return base;
};
