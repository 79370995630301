import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { jsonApi } from '@libero/utilities/api-client';

import type { TicketStatus } from './types';

const ticketStatus = jsonApi('ticket-status');

const index = (): Promise<TicketStatus[]> => {
  return ticketStatus.get();
};

export const ticketStatusApi = {
  name: 'ticket-status',
  index,
  scout: generateResourceScoutApi<TicketStatus>('ticket-status', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
