import { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { Count, CountQuery } from '@libero/types/Count';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { StoreUserRequest, UpdateUserRequest, User } from './types';

const user = jsonApi('user');

const index = (query?: PaginationQuery): Promise<PaginationResource<User>> => {
  return user.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<User> => {
  return user.get(id.toString());
};

const store = (data: StoreUserRequest): Promise<User> => {
  return user.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: UpdateUserRequest): Promise<unknown> => {
    return user.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<unknown> => {
  return user.delete(id.toString());
};

const favorite = (id: string | number): Promise<unknown> => {
  return user.post(`${id}/favorite`);
};

const activity = (id: string | number): Promise<Activity[]> => {
  return user.get(`${id}/activity`);
};

const count = (query?: CountQuery): Promise<Count> => {
  return user.get('all/count', {
    searchParams: QueryString.stringify(query),
  });
};

export const userApi = {
  name: 'user',
  index,
  show,
  store,
  update,
  destroy,
  favorite,
  activity,
  count,
  view: generateResourceViewApi('user'),
  scout: generateResourceScoutApi<User>('user', (item) => ({
    label: [item.full_name, item.organisation?.name].filter(Boolean).join(' - '),
    value: item.id,
  })),
};
