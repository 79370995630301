<script setup lang="ts">
import { viewApi } from '@libero/api-client/view/api';
import type { UpdateViewRequest, View } from '@libero/api-client/view/types';
import { useForm } from '@libero/hooks/useForm';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import FormLayout from '@libero/ui-framework/FormLayout/FormLayout.vue';
import Input from '@libero/ui-framework/Input/Input.vue';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import Label from '@libero/ui-framework/Label/Label.vue';
import ModalActions from '@libero/ui-framework/ModalActions/ModalActions.vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  view: View;
  onClose: () => void;
}

const props = defineProps<Props>();

const { t } = useI18n();
const queryClient = useQueryClient();

const form = useForm<UpdateViewRequest>(
  computed(() => ({
    name: props.view.name,
  })),
);

const { mutateAsync, isPending } = useMutation({
  mutationFn: viewApi.update(props.view.id),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['view.index'] });
    queryClient.invalidateQueries({
      queryKey: [`tenant.view.show`, props.view.id],
    });

    message.success(t('saved'));

    props.onClose();
  },
});

const handleSubmit = () => {
  form.submit(mutateAsync);
};
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <FormLayout>
      <InputGroup>
        <Label for="name" :value="t('name')" required />
        <Input v-bind="form.register('name')" />
      </InputGroup>
    </FormLayout>

    <ModalActions>
      <Cluster justifyContent="end">
        <Button appearance="outline" :onClick="onClose">
          {{ t('cancel') }}
        </Button>

        <Button :label="t('edit')" type="submit" :isLoading="isPending" />
      </Cluster>
    </ModalActions>
  </Form>
</template>
