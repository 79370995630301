import { PaginationQuery, PaginationResource } from '@libero/api-client/types/pagination';
import { AttachRequest, DetachRequest } from '@libero/api-client/types/relation';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import { Ticket } from './types';

export interface TicketRelationApi {
  name: string;
  index: (query?: PaginationQuery) => Promise<PaginationResource<Ticket>>;
  attach: (data: AttachRequest) => Promise<void>;
  detach: (data: DetachRequest) => Promise<void>;
}

export const ticketRelationApi =
  (relationName: string) =>
  (relationId: string | number): TicketRelationApi => {
    const ticketRelation = jsonApi(`${relationName}/${relationId}/ticket`);

    const index = (query?: PaginationQuery): Promise<PaginationResource<Ticket>> => {
      return ticketRelation.get(undefined, {
        searchParams: QueryString.stringify(query),
      });
    };

    const attach = (data: AttachRequest): Promise<void> => {
      return ticketRelation.post('attach', data);
    };

    const detach = (data: DetachRequest): Promise<void> => {
      return ticketRelation.post('detach', data);
    };

    return {
      name: `${relationName}-${relationId}-ticket`,
      index,
      attach,
      detach,
    };
  };
