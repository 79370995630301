import type { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { Date } from '@libero/types/Date';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { Contract, StoreContractRequest } from './types';

const contract = jsonApi('contract');

const index = (query?: PaginationQuery): Promise<PaginationResource<Contract>> => {
  return contract.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<Contract> => {
  return contract.get(id.toString());
};

const store = (data: StoreContractRequest): Promise<Contract> => {
  return contract.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StoreContractRequest): Promise<Contract> => {
    return contract.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<unknown> => {
  return contract.delete(id.toString());
};

const activity = (id: string | number): Promise<Activity[]> => {
  return contract.get(`${id}/activity`);
};

const lastDate = (id: string | number): Promise<Date> => {
  return contract.get(`${id}/last-date`);
};

export const contractApi = {
  name: 'contract',
  index,
  show,
  store,
  update,
  destroy,
  activity,
  lastDate,
  view: generateResourceViewApi('contract'),
  scout: generateResourceScoutApi<Contract>('contract', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
