<script setup lang="ts">
import { useForm } from '@libero/hooks/useForm';
import { FilterOperator } from '@libero/types/Filter';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import DatePicker from '@libero/ui-framework/DatePicker/DatePicker.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  value?: string;
  onUpdate: (value?: string) => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const form = useForm(
  computed(() => {
    const [type = FilterOperator.Equal, values] = props.value?.split('|') || [];
    const [valueDefault = null, valueBetween = null] = values?.split('*') || [];

    return {
      type: type as FilterOperator,
      valueDefault,
      valueBetween,
    };
  }),
);

const handleSubmit = () => {
  const value = `${form.values.type}|${form.values.valueDefault}`;
  const valueBetween =
    form.values.type === FilterOperator.Between ? `*${form.values.valueBetween}` : '';

  props.onUpdate(`${value}${valueBetween}`);
};

const handleClear = () => {
  form.values.type = FilterOperator.Before;
  form.clearValues(['valueDefault', 'valueBetween']);
  props.onUpdate(undefined);
};

const hasInput = computed(() =>
  [
    FilterOperator.Equal,
    FilterOperator.Before,
    FilterOperator.Between,
    FilterOperator.After,
  ].includes(form.values.type),
);

const hasSecondInput = computed(() => form.values.type === FilterOperator.Between);

const isDisabled = computed(
  () =>
    (!form.values.valueDefault && hasInput.value) ||
    (!form.values.valueBetween && hasSecondInput.value),
);
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <Stack>
      <Stack :gap="0.5">
        <Select
          size="sm"
          :options="[
            { label: t('data-type.condition-options.equal'), value: FilterOperator.Equal },
            { label: t('data-type.condition-options.before'), value: FilterOperator.Before },
            { label: t('data-type.condition-options.between'), value: FilterOperator.Between },
            { label: t('data-type.condition-options.after'), value: FilterOperator.After },
            { label: t('data-type.condition-options.empty'), value: FilterOperator.Empty },
            { label: t('data-type.condition-options.not-empty'), value: FilterOperator.NotEmpty },
          ]"
          v-bind="form.register('type')"
        />

        <Stack v-if="hasInput" :gap="0.25">
          <DatePicker preset="date" size="sm" v-bind="form.register('valueDefault')" />

          <template v-if="hasSecondInput">
            <Typography bold>{{ t('and') }}</Typography>

            <DatePicker preset="date" size="sm" v-bind="form.register('valueBetween')" />
          </template>
        </Stack>
      </Stack>

      <Cluster alignItems="center">
        <Button type="submit" :isDisabled="isDisabled" size="sm">
          {{ t('apply') }}
        </Button>

        <Button color="secondary" :isDisabled="!value" appearance="link" :onClick="handleClear">
          {{ t('clear') }}
        </Button>
      </Cluster>
    </Stack>
  </Form>
</template>
