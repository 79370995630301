import { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { PaginationQuery, PaginationResource } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { PowerSchedule, StorePowerScheduleRequest } from './types';

const powerSchedule = jsonApi('power-schedule');

const index = (query?: PaginationQuery): Promise<PaginationResource<PowerSchedule>> => {
  return powerSchedule.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<PowerSchedule> => {
  return powerSchedule.get(id.toString());
};

const store = (data: StorePowerScheduleRequest): Promise<PowerSchedule> => {
  return powerSchedule.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StorePowerScheduleRequest): Promise<PowerSchedule> => {
    return powerSchedule.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<void> => {
  return powerSchedule.delete(id.toString());
};

const activity = (id: string | number): Promise<Activity[]> => {
  return powerSchedule.get(`${id}/activity`);
};

export const powerScheduleApi = {
  name: 'power-schedule',
  index,
  show,
  store,
  update,
  destroy,
  activity,
  view: generateResourceViewApi('power-schedule'),
  scout: generateResourceScoutApi<PowerSchedule>('power-schedule', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
