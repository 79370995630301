<script setup lang="ts">
import type { Column } from '@libero/api-client/column/types';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import Create from '@libero/cityview-overview/modules/view/views/forms/Create.vue';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  resourceApi: Pick<ResourceApi, 'name'>;
  columns: Column[];
  onDone: (id: string) => void;
}

defineProps<Props>();

const { t } = useI18n();

const isOpen = ref(false);

const toggleVisible = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <slot :openModal="toggleVisible" />

  <Modal :isOpen="isOpen" :title="t('view.add-view')" :onCancel="toggleVisible">
    <ModalContent>
      <Create
        :resourceApi="resourceApi"
        :columns="columns"
        :onClose="toggleVisible"
        :onDone="onDone"
      />
    </ModalContent>
  </Modal>
</template>
