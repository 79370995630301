import { Activity } from '@libero/api-client/activity/types';
import { containerRelationApi } from '@libero/api-client/container/relation';
import { Map, MapQuery } from '@libero/api-client/map/types';
import { mediaRelationApi } from '@libero/api-client/media/relation';
import { noteRelationApi } from '@libero/api-client/note/relation';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { Color } from '@libero/types/Color';
import { jsonApi } from '@libero/utilities/api-client';
import { getColor } from '@libero/utilities/color';
import { getThemeIcon } from '@libero/utilities/theme';
import QueryString from 'qs';

import { ticketRelationApi } from './relation';
import type { MergeTicketRequest, StoreTicketRequest, Ticket, UpdateTicketRequest } from './types';

const ticket = jsonApi('ticket');

const index = (query?: PaginationQuery): Promise<PaginationResource<Ticket>> => {
  return ticket.post('index', query);
};

const indexIds = (query?: PaginationQuery): Promise<string[]> => {
  return ticket.post('id', query);
};

const exp = (query?: PaginationQuery): Promise<void> => {
  return ticket.post('export', query);
};

const map = (query?: MapQuery): Promise<Map> => {
  return ticket
    .get<Map>('map', {
      searchParams: QueryString.stringify(query),
    })
    .then((response) => {
      return {
        ...response,
        features: response.features.map((feature) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              color: getColor(feature.properties.color),
            },
          };
        }),
      };
    });
};

const mapSingle = (id: string | number): Promise<Map> => {
  return ticket.get(`${id}/map`);
};

const mapItems = (query?: PaginationQuery): Promise<Ticket[]> => {
  return ticket.get('map/items', {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<Ticket> => {
  return ticket.get(id.toString());
};

const store = (data: StoreTicketRequest): Promise<Ticket> => {
  return ticket.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: UpdateTicketRequest): Promise<Ticket> => {
    return ticket.put(id.toString(), data);
  };

const merge =
  (id: string | number) =>
  (data: MergeTicketRequest): Promise<Ticket> => {
    return ticket.put(`${id}/merge`, data);
  };

const favorite = (id: string | number): Promise<unknown> => {
  return ticket.post(`${id}/favorite`);
};

const user = (id: string | number): Promise<Ticket[]> => {
  return ticket.get(`user/${id}`);
};

const activity = (id: string | number): Promise<Activity[]> => {
  return ticket.get(`${id}/activity`);
};

export const ticketApi = {
  name: 'ticket',
  index,
  indexIds,
  export: exp,
  map,
  mapSingle,
  mapItems,
  show,
  store,
  update,
  merge,
  favorite,
  user,
  activity,
  view: generateResourceViewApi('ticket'),
  scout: generateResourceScoutApi<Ticket>('ticket', (item) => ({
    icon: item.theme ? getThemeIcon(item.theme.key) : undefined,
    iconColor: Color.primary800,
    label: item.number.toString(),
    subLabel: item.ticket_subject?.name,
    value: item.id,
  })),
  relation: {
    container: containerRelationApi('ticket'),
    ticket: ticketRelationApi('ticket'),
    note: noteRelationApi('ticket'),
    media: mediaRelationApi('ticket'),
  },
};
