<script setup lang="ts">
import { ClockIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { TimePicker as AntTimePicker } from 'ant-design-vue';
import dayjs from 'dayjs';
import { ComponentPublicInstance, computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  id: string;
  name: string;
  value: string | null;
  error?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  disabled?: boolean;
  minWidth?: string;
  onUpdate: (value: string | null) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  error: undefined,
  size: 'md',
  minWidth: undefined,
  onMount: undefined,
  onUnmount: undefined,
});

const { t } = useI18n();

const timePicker = ref<ComponentPublicInstance<typeof AntTimePicker>>();

const time = computed(() => {
  if (props.value) {
    return dayjs(props.value, 'HH:mm');
  }

  return '';
});

const classes = computed(() => {
  return {
    ['size-' + props.size]: true,
  };
});

const handleUpdate = (value: string | dayjs.Dayjs | null) => {
  if (dayjs.isDayjs(value)) {
    return props.onUpdate(value.format('HH:mm'));
  }

  props.onUpdate(value);
};

onMounted(() => {
  props.onMount?.(timePicker.value?.focus, props.disabled);
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0.5">
    <InputGroup>
      <AntTimePicker
        :id="id"
        :name="name"
        :class="classes"
        :value="time"
        format="HH:mm"
        :disabled="disabled"
        :placeholder="t('select-time')"
        :style="{ minWidth }"
        :onUpdate:value="handleUpdate"
      >
        <template #suffixIcon>
          <ClockIcon />
        </template>

        <template #clearIcon>
          <XMarkIcon />
        </template>
      </AntTimePicker>
    </InputGroup>

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/TimePicker/TimePicker.scss';
</style>
