/* eslint-disable @typescript-eslint/no-explicit-any */

export enum FilterType {
  String = 'string',
  Date = 'date',
  Year = 'year',
  Time = 'time',
  Boolean = 'boolean',
  Number = 'number',
  Enumeration = 'enum',
  Product = 'product',
  Organisation = 'organisation',
  DimCalendar = 'dim-calendar',
  PowerSchedule = 'power-schedule',
  Money = 'money',
  TicketTime = 'ticket-time',
  LeadTime = 'lead-time',
  LeadTimeDiscount = 'lead-time-discount',
  LeadTimeStatus = 'lead-time-status',
}

export type FilterTypes = {
  [key in FilterType]?: any;
};

export enum AttributeFilterType {
  String = 'a-string',
  Date = 'a-date',
  Year = 'a-year',
  Time = 'a-time',
  Boolean = 'a-boolean',
  Number = 'a-number',
  Enumeration = 'a-enum',
}

export enum FilterOperator {
  Equal = 'equal',
  NotEqual = 'not-equal',
  Like = 'like',
  Empty = 'empty',
  NotEmpty = 'not-empty',
  Before = 'before',
  Between = 'between',
  After = 'after',
  OnTime = 'on-time',
  OverTime = 'over-time',
  InTime = 'in-time',
}

export type QueryFilter = FilterTypes & {
  id?: string | number;
  scout?: string;
  parent_id?: string;
  themes?: number | string;
} & {
  [key: string]: string | number | boolean | undefined;
};
