import type { Column } from '@libero/api-client/column/types';
import { StoreExportRequest } from '@libero/api-client/export/types';
import type { PaginationQuery, PaginationQueryParams } from '@libero/api-client/types/pagination';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import { viewApi } from '@libero/api-client/view/api';
import type { View } from '@libero/api-client/view/types';
import { useMutateStore } from '@libero/hooks/store/useMutateStore';
import { useQueryParams } from '@libero/hooks/useQueryParams';
import { keepPreviousData, useQueryClient } from '@tanstack/vue-query';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { isEmpty, isEqual, map, omit, pick } from 'lodash';
import { MaybeRef, nextTick, unref } from 'vue';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { LocationQueryRaw, useRouter } from 'vue-router';

import { useUserStore } from '../store/useUserStore';
import { useLocalStorage } from '../useLocalStorage';

export interface UseResourceView {
  view?: View;
  columns: Column[];
  hasChanges: boolean;
  hasBreakingChanges: boolean;
  isMap: boolean;
  handleSelectView: (id?: string, resetFilter?: boolean) => void;
  handleClearView: () => void;
  handleExport: (queryAndData: PaginationQuery & StoreExportRequest) => Promise<unknown>;
  handleUpdateColumns: (columns: Column[]) => void;
  handleReset: () => void;
}

export const useResourceView = (
  resourceApiProp: MaybeRef<Pick<ResourceApi, 'name' | 'index' | 'export' | 'view'>>,
  isMap = false,
): UseResourceView => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const { t } = useI18n();
  const { user } = useUserStore();
  const mutateStore = useMutateStore();
  const { params, appendParams, overwriteParams } = useQueryParams<PaginationQueryParams>();

  const resourceApi = computed(() => unref(resourceApiProp));

  const [savedView, setSavedView] = useLocalStorage<string>(
    `${user?.id}.${resourceApi.value.name}.saved-view`,
  );

  const columns = ref<Column[]>(mutateStore.columns || []);

  const handleUpdateColumns = (newColumns: Column[]) => {
    columns.value = newColumns;
  };

  const handleReset = () => {
    router.push({ query: { filter: view.value?.query.filter } as LocationQueryRaw });

    if (view.value) {
      columns.value = view.value.columns;
    }
  };

  const handleViewData = (view?: View) => {
    if (!view) {
      handleClearView();
      return;
    }

    if (view.id) {
      setSavedView(view.id);
      queryClient.setQueryData([`${resourceApi.value.name}.view.show`, view.id], view);
    }

    if (!mutateStore.isMutating || !columns.value.length) {
      columns.value = view.columns;
    }

    overwriteParams(
      {
        per_page: isMap ? undefined : view.query.per_page,
        view: view.id,
        sort: isMap ? undefined : view.query.sort,
        page: isMap ? undefined : params.value.page,
        filter: view.query.filter || params.value.filter,
      },
      true,
    );
  };

  const currentView = computed(() => {
    if (params.value.view) return params.value.view;
    if (params.value.filter) return 'empty';
    if (savedView.value) return savedView.value;
    return 'default';
  });

  const {
    data: view,
    refetch,
    isError,
    isFetched,
  } = useQuery({
    queryKey: [`${resourceApi.value.name}.view.show`, currentView],
    queryFn: async () => {
      switch (currentView.value) {
        case 'empty':
          return resourceApi.value.view.empty();
        case 'default':
          return resourceApi.value.view.default();
        default:
          return viewApi.show(currentView.value);
      }
    },
    placeholderData: keepPreviousData,
  });

  const { mutateAsync: handleExport } = useMutation({
    mutationFn: (queryAndData: PaginationQuery & StoreExportRequest) => {
      return (
        resourceApi.value.export?.({
          ...queryAndData,
          columns: map(columns.value, 'key').join(','),
        }) || Promise.resolve()
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['export.index'] });
      message.success(t('export.started'));
    },
  });

  const { mutateAsync: addTab } = useMutation({
    mutationFn: viewApi.addTab,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`${resourceApi.value.name}.view.tabs`] });
    },
  });

  const handleSelectView = (id?: string, resetFilter = false) => {
    if (resetFilter) {
      setSavedView(undefined);
      return appendParams({ view: id, filter: undefined });
    }

    appendParams({ view: id });

    if (id) {
      addTab(id);
    }
  };

  const handleClearView = () => {
    setSavedView(undefined);
    appendParams({ view: undefined, filter: undefined });
  };

  const hasChanges = computed(() => {
    const query = {
      ...pick(params.value || {}, isMap ? ['filter'] : ['filter', 'per_page', 'sort']),
      filter: omit(params.value?.filter || {}, ['parent_id', 'scout']),
    };

    const viewQuery = {
      ...pick(view.value?.query || {}, isMap ? ['filter'] : ['filter', 'per_page', 'sort']),
      filter: omit(view.value?.query.filter || {}, ['parent_id', 'scout']),
    };

    return (
      !isEmpty(query) &&
      isFetched.value &&
      (!isEqual(columns.value, view.value?.columns) || !isEqual(query, viewQuery))
    );
  });

  const hasBreakingChanges = computed(() => {
    if (view.value && view.value.is_default) return false;

    const query = {
      ...pick(params.value || {}, ['filter']),
      filter: omit(params.value?.filter || {}, ['parent_id', 'scout']),
    };

    const viewQuery = {
      ...pick(view.value?.query || {}, ['filter']),
      filter: omit(view.value?.query.filter || {}, ['parent_id', 'scout']),
    };

    return (
      !isEmpty(query) &&
      isFetched.value &&
      (!isEqual(columns.value, view.value?.columns) || !isEqual(query, viewQuery))
    );
  });

  watch(view, () => {
    handleViewData(view.value);
  });

  onMounted(() => {
    if (view.value) {
      handleViewData(view.value);
    }
  });

  watch(isError, () => {
    if (isError.value) {
      if (currentView.value) {
        return appendParams({ view: undefined });
      }

      if (savedView.value) {
        setSavedView(undefined);
        nextTick(refetch);
      }
    }
  });

  return reactive({
    view,
    columns,
    hasChanges,
    hasBreakingChanges,
    isMap,
    handleExport,
    handleSelectView,
    handleClearView,
    handleUpdateColumns,
    handleReset,
  });
};
