<script setup lang="ts">
import type { Column } from '@libero/api-client/column/types';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { FilterDataTypes, getFilterType } from '@libero/data-types/filter';
import { useQueryParams } from '@libero/hooks/useQueryParams';
import { FilterType } from '@libero/types/Filter';
import { parseFilters } from '@libero/utilities/filtering';
import { computed } from 'vue';

interface Props {
  column: Column;
}

const props = defineProps<Props>();

const { params, appendParams } = useQueryParams<PaginationQuery>();

const type = computed(() =>
  props.column.attribute_type_id
    ? (`a-${getFilterType(props.column)}` as FilterType)
    : getFilterType(props.column),
);

const key = computed(
  () =>
    props.column.key ||
    [props.column.entity_type_id, props.column.attribute_type_id].filter(Boolean).join('|'),
);

const value = computed(() => {
  const filters = parseFilters(params.value.filter);
  const typeValues = filters?.[type.value];

  const value = typeValues
    ?.find((string: string) => string.startsWith(`${key.value}|`))
    ?.replace(`${key.value}|`, '');

  return value;
});

const handleUpdate = (value: string) => {
  const filterQuery = `${key.value}|${value}`;

  const filters = params.value.filter?.[type.value];
  const typeValues = filters ? JSON.parse(filters) : [];
  const index = typeValues.findIndex((string: string) => string.startsWith(key.value));

  if (index !== -1) {
    if (value) {
      typeValues[index] = filterQuery;
    } else {
      typeValues.splice(index, 1);
    }
  } else {
    typeValues.push(filterQuery);
  }

  appendParams({
    filter: {
      [type.value]: typeValues.length ? JSON.stringify(typeValues) : undefined,
    },
  });
};
</script>

<template>
  <component
    :is="FilterDataTypes[column.data_type]"
    :id="key"
    :value="value"
    :attributeType="column.attribute_type"
    :entityTypeId="column.entity_type_id"
    :enumerationValues="column.enumeration_values"
    :onUpdate="handleUpdate"
  />
</template>
