import type { Activity } from '@libero/api-client/activity/types';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { ResourceApi } from '@libero/api-client/types/resource-api';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import { generateResourceScoutApi } from '../scout/api';
import type { LeadTime, StoreLeadTimeRequest } from './types';

export const generateLeadTimeApi = (
  contractId?: string,
): ResourceApi<LeadTime, StoreLeadTimeRequest, StoreLeadTimeRequest> => {
  const leadTime = jsonApi(`contract/${contractId}/lead-time`);

  const index = (query?: PaginationQuery): Promise<PaginationResource<LeadTime>> => {
    return leadTime.get(undefined, {
      searchParams: QueryString.stringify(query),
    });
  };

  const show = (id: string | number): Promise<LeadTime> => {
    return leadTime.get(id.toString());
  };

  const store = (data: StoreLeadTimeRequest): Promise<LeadTime> => {
    return leadTime.post(undefined, data);
  };

  const update =
    (id: string | number) =>
    (data: StoreLeadTimeRequest): Promise<LeadTime> => {
      return leadTime.put(id.toString(), data);
    };

  const destroy = (id: string | number): Promise<unknown> => {
    return leadTime.delete(id.toString());
  };

  const activity = (id: string | number): Promise<Activity[]> => {
    return leadTime.get(`${id}/activity`);
  };

  return {
    name: 'lead-time',
    parentName: 'contract',
    parentId: contractId,
    permissionBase: 'contract',
    index,
    show,
    store,
    update,
    destroy,
    activity,
    view: generateResourceViewApi('lead-time'),
  };
};

export const leadTimeApi = {
  name: 'lead-time',
  scout: generateResourceScoutApi<LeadTime>('lead-time', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
