<script setup lang="ts">
import { View } from '@libero/api-client/view/types';
import Modal from '@libero/ui-framework/Modal/Modal.vue';
import ModalContent from '@libero/ui-framework/Modal/ModalContent.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import Edit from '../forms/Edit.vue';

interface Props {
  view: View;
}

defineProps<Props>();

const { t } = useI18n();

const isOpen = ref(false);

const toggleVisible = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <slot :openModal="toggleVisible" />

  <Modal :isOpen="isOpen" :title="t('view.change-name')" :onCancel="toggleVisible">
    <ModalContent>
      <Edit :view="view" :onClose="toggleVisible" />
    </ModalContent>
  </Modal>
</template>
