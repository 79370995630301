export enum DataType {
  String = 'StringType',
  Float = 'FloatType',
  Date = 'DateType',
  Boolean = 'BooleanType',
  Year = 'YearType',
  Decimal = 'DecimalType',
  Text = 'TextType',
  Integer = 'IntegerType',
  Time = 'TimeType',
  DateTime = 'DateTimeType',
  Enumeration = 'EnumerationType',
  Geometry = 'GeometryType',
  Organisation = 'Organisation',
  Product = 'Product',
  DimCalendar = 'DimCalendar',
  PowerSchedule = 'PowerSchedule',
  Money = 'MoneyType',
  URL = 'UrlType',
  TicketTime = 'TicketTime',
  LeadTime = 'LeadTime',
  LeadTimeDiscount = 'LeadTimeDiscount',
  LeadTimeStatus = 'LeadTimeStatus',
}
