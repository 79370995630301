<script setup lang="ts">
import DatePicker from '@libero/ui-framework/DatePicker/DatePicker.vue';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

interface Props {
  id: string;
  name: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value: string | null;
  isDirty?: boolean;
  onUpdate: (value: string | null) => void;
  onUnmount?: () => void;
}

const props = defineProps<Props>();

const handleUpdate = (value: string | Dayjs) => {
  if (!value) return props.onUpdate(null);

  return props.onUpdate(dayjs(value).format('YYYY'));
};
</script>

<template>
  <InputGroup>
    <DatePicker
      :id="id"
      :name="name"
      :value="value"
      preset="year"
      :size="size"
      isClearable
      :isDirty="isDirty"
      minWidth="10rem"
      :onUpdate="handleUpdate"
      :onUnmount="onUnmount"
    />
  </InputGroup>
</template>
