import { Activity } from '@libero/api-client/activity/types';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { PaginationQuery, PaginationResource } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import { Color } from '@libero/types/Color';
import { jsonApi } from '@libero/utilities/api-client';
import { getThemeIcon } from '@libero/utilities/theme';
import QueryString from 'qs';

import type { StoreTicketSubjectRequest, TicketSubject } from './types';

const ticketSubject = jsonApi('ticket-subject');

const index = (query?: PaginationQuery): Promise<PaginationResource<TicketSubject>> => {
  return ticketSubject.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<TicketSubject> => {
  return ticketSubject.get(id.toString());
};

const store = (data: StoreTicketSubjectRequest): Promise<TicketSubject> => {
  return ticketSubject.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: StoreTicketSubjectRequest): Promise<TicketSubject> => {
    return ticketSubject.put(id.toString(), data);
  };

const destroy = (id: string | number): Promise<void> => {
  return ticketSubject.delete(id.toString());
};

const activity = (id: string | number): Promise<Activity[]> => {
  return ticketSubject.get(`${id}/activity`);
};

export const ticketSubjectApi = {
  name: 'ticket-subject',
  index,
  show,
  store,
  update,
  destroy,
  activity,
  view: generateResourceViewApi('ticket-subject'),
  scout: generateResourceScoutApi<TicketSubject>('ticket-subject', (item) => ({
    icon: item.theme ? getThemeIcon(item.theme.key) : undefined,
    iconColor: Color.primary800,
    label: item.name,
    subLabel: item.ticket_type.name,
    value: item.id,
  })),
};
