<script setup lang="ts">
import { Column } from '@libero/api-client/column/types';
import { PaginationQueryParams } from '@libero/api-client/types/pagination';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import { viewApi } from '@libero/api-client/view/api';
import type { StoreViewRequest } from '@libero/api-client/view/types';
import { useForm } from '@libero/hooks/useForm';
import { useQueryParams } from '@libero/hooks/useQueryParams';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import FormLayout from '@libero/ui-framework/FormLayout/FormLayout.vue';
import Input from '@libero/ui-framework/Input/Input.vue';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import Label from '@libero/ui-framework/Label/Label.vue';
import ModalActions from '@libero/ui-framework/ModalActions/ModalActions.vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { map } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  resourceApi: Pick<ResourceApi, 'name'>;
  columns: Column[];
  onClose: () => void;
  onDone: (id: string) => void;
}

const props = defineProps<Props>();

const { t } = useI18n();
const queryClient = useQueryClient();
const { params } = useQueryParams<PaginationQueryParams>();

const form = useForm<StoreViewRequest>(
  computed(() => ({
    resource: `landlord-${props.resourceApi.name}`,
    name: null,
    columns: map(props.columns, 'key'),
    query: params.value,
  })),
);

const { mutateAsync, isPending } = useMutation({
  mutationFn: viewApi.store,
  onSuccess: (view) => {
    queryClient.invalidateQueries({ queryKey: ['view.index'] });

    message.success(t('saved'));

    props.onClose();
    props.onDone(view.id);
  },
});

const handleSubmit = () => {
  form.submit(mutateAsync);
};
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <FormLayout>
      <InputGroup>
        <Label for="name" :value="t('name')" required />
        <Input v-bind="form.register('name')" />
      </InputGroup>
    </FormLayout>

    <ModalActions>
      <Cluster justifyContent="end">
        <Button appearance="outline" :onClick="onClose">
          {{ t('cancel') }}
        </Button>

        <Button :label="t('create')" type="submit" :isLoading="isPending" />
      </Cluster>
    </ModalActions>
  </Form>
</template>
