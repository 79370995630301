<script setup lang="ts">
import { FunnelIcon } from '@heroicons/vue/24/solid';
import { tenantApi } from '@libero/api-client/tenant/api';
import ResourceViews from '@libero/cityview-overview/modules/resource/segments/ResourceViews/ResourceViews.vue';
import Filters from '@libero/cityview-overview/modules/resource/views/drawers/Filters.vue';
import { useResourceView } from '@libero/hooks/resource/useResourceView';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Image from '@libero/ui-framework/Image/Image.vue';
import PageTitle from '@libero/ui-framework/PageTitle/PageTitle.vue';
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';

const route = useRoute();

const resourceView = useResourceView(tenantApi);

const logo = computed(
  () => `/assets/environments/${import.meta.env.VITE_ENVIRONMENT}/apple-touch-icon.png`,
);
</script>

<template>
  <div class="app-layout">
    <div class="app-layout-header">
      <Cluster flex="1" alignItems="center" justifyContent="space-between" :gap="1.5">
        <Cluster alignItems="center" :gap="0.75">
          <Image :url="logo" class="app-layout-logo" />
          <PageTitle :title="route.meta.title" />
        </Cluster>

        <Cluster :gap="0.75">
          <ResourceViews :resourceApi="tenantApi" :resourceView="resourceView" />

          <Filters v-slot="{ isOpen, openDrawer }" :resourceApi="tenantApi">
            <Button size="sm" appearance="select" :isActive="isOpen" :onClick="openDrawer">
              <template #icon>
                <FunnelIcon />
              </template>
            </Button>
          </Filters>
        </Cluster>
      </Cluster>
    </div>

    <div class="app-layout-content">
      <RouterView />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/cityview-overview/modules/app/views/layouts/AppLayout/AppLayout.scss';
</style>
