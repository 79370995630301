import { PaginationQuery, PaginationResource } from '@libero/api-client/types/pagination';
import { AttachRequest, DetachRequest } from '@libero/api-client/types/relation';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import { Container } from './types';

interface ContainerRelationApi {
  name: string;
  index: (query?: PaginationQuery) => Promise<PaginationResource<Container>>;
  attach: (data: AttachRequest) => Promise<void>;
  detach: (data: DetachRequest) => Promise<void>;
}

export const containerRelationApi =
  (relationName: string) =>
  (relationId: string | number): ContainerRelationApi => {
    const containerRelation = jsonApi(`${relationName}/${relationId}/container`);

    const index = (query?: PaginationQuery): Promise<PaginationResource<Container>> => {
      return containerRelation.get(undefined, {
        searchParams: QueryString.stringify(query),
      });
    };

    const attach = (data: AttachRequest): Promise<void> => {
      return containerRelation.post('attach', data);
    };

    const detach = (data: DetachRequest): Promise<void> => {
      return containerRelation.post('detach', data);
    };

    return {
      name: `${relationName}-${relationId}-container`,
      index,
      attach,
      detach,
    };
  };
