<script setup lang="ts">
import { Column } from '@libero/api-client/column/types';
import { PaginationQuery } from '@libero/api-client/types/pagination';
import { isFilterActive } from '@libero/data-types/filter';
import { useQueryParams } from '@libero/hooks/useQueryParams';
import Filter from '@libero/organisms/Filter/Filter.vue';
import Badge from '@libero/ui-framework/Badge/Badge.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Collapse from '@libero/ui-framework/Collapse/Collapse.vue';
import CollapseItem from '@libero/ui-framework/Collapse/CollapseItem.vue';
import FadeMotion from '@libero/ui-framework/FadeMotion/FadeMotion.vue';

interface Props {
  columns?: Column[];
}

withDefaults(defineProps<Props>(), {
  columns: () => [],
});

const { params } = useQueryParams<PaginationQuery>();
</script>

<template>
  <Collapse>
    <template v-for="column in columns">
      <CollapseItem v-if="column.label" :id="column.key" :key="column.key">
        <template #title>
          <Cluster alignItems="center" justifyContent="space-between">
            {{ column.label }}

            <FadeMotion>
              <Badge v-if="isFilterActive(params, column)" />
            </FadeMotion>
          </Cluster>
        </template>

        <Filter :column="column" />
      </CollapseItem>
    </template>
  </Collapse>
</template>
