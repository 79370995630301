import { isUndefined } from 'lodash';
import { Ref, ref } from 'vue';

export interface Register<Value> {
  id: string;
  name: string;
  value: Value;
  onUpdate: (newValue: Value) => void;
}

type UseInput<Value> = [Ref<Value>, () => Register<Value>, (value: Value) => void];

type UseInputOverload = {
  <Value = string>(name: string): UseInput<Value | null>;
  <Value = string>(name: string, defaultValue: Value): UseInput<Value>;
};

export const useInput: UseInputOverload = <Value = string>(
  name: string,
  defaultValue?: Value,
): UseInput<Value> => {
  const value = ref<Value>(
    isUndefined(defaultValue) ? (null as Value) : defaultValue,
  ) as Ref<Value>;

  const register = () => {
    const onUpdate = (newValue: Value) => {
      value.value = newValue;
    };

    const onPressEnter = (event: Event) => {
      event?.preventDefault();
    };

    return {
      id: name,
      name: name,
      value: value.value,
      onUpdate,
      onPressEnter,
    };
  };

  const update = (newValue: Value) => {
    value.value = newValue;
  };

  return [value, register, update];
};
