<script setup lang="ts">
import { useForm } from '@libero/hooks/useForm';
import { FilterOperator } from '@libero/types/Filter';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  value?: string | null;
  onUpdate: (value?: string) => void;
}

const props = withDefaults(defineProps<Props>(), {
  value: undefined,
  attributeType: undefined,
  enumerationValues: undefined,
});

const { t } = useI18n();

const form = useForm(
  computed(() => {
    const [type = FilterOperator.InTime, values] = props.value?.split('|') || [];

    return {
      type: type as FilterOperator,
      value: values?.split(';') || [],
    };
  }),
);

const handleClear = () => {
  form.values.type = FilterOperator.InTime;
  props.onUpdate(undefined);
};

const handleSubmit = () => {
  return props.onUpdate(`${form.values.type}`);
};
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <Stack>
      <Stack :gap="0.75">
        <Select
          size="sm"
          :options="[
            { label: t('data-type.condition-options.in-time'), value: FilterOperator.InTime },
            { label: t('data-type.condition-options.over-time'), value: FilterOperator.OverTime },
          ]"
          v-bind="form.register('type')"
        />
      </Stack>

      <Cluster alignItems="center">
        <Button type="submit" size="sm">
          {{ t('apply') }}
        </Button>

        <Button color="secondary" appearance="link" :onClick="handleClear">
          {{ t('clear') }}
        </Button>
      </Cluster>
    </Stack>
  </Form>
</template>
