import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { getTicketTypeIcon, getTicketTypeIconColor } from '@libero/utilities/ticket-type';

import type { TicketType } from './types';

export const ticketTypeApi = {
  name: 'ticket-type',
  scout: generateResourceScoutApi<TicketType>('ticket-type', (item) => ({
    icon: getTicketTypeIcon(item.key),
    iconColor: getTicketTypeIconColor(item.key),
    label: item.name,
    value: item.id,
  })),
};
