<script setup lang="ts">
import { causeApi } from '@libero/api-client/cause/api';
import { containerApi } from '@libero/api-client/container/api';
import { contractApi } from '@libero/api-client/contract/api';
import { dimCalendarApi } from '@libero/api-client/dim-calendar/api';
import { entityTypeApi } from '@libero/api-client/entity-type/api';
import { externalEndpointApi } from '@libero/api-client/external-endpoint/api';
import { leadTimeApi } from '@libero/api-client/lead-time/api';
import { organisationApi } from '@libero/api-client/organisation/api';
import { powerScheduleApi } from '@libero/api-client/power-schedule/api';
import { productApi } from '@libero/api-client/product/api';
import { roleApi } from '@libero/api-client/role/api';
import { ScoutQuery } from '@libero/api-client/scout/types';
import { themeApi } from '@libero/api-client/theme/api';
import { ticketApi } from '@libero/api-client/ticket/api';
import { ticketStatusApi } from '@libero/api-client/ticket-status/api';
import { ticketSubjectApi } from '@libero/api-client/ticket-subject/api';
import { ticketTypeApi } from '@libero/api-client/ticket-type/api';
import type { Resource } from '@libero/api-client/types/resource';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import { userApi } from '@libero/api-client/user/api';
import { viewApi } from '@libero/api-client/view/api';
import { useDebounce } from '@libero/hooks/useDebounce';
import { useDebouncedValue } from '@libero/hooks/useDebouncedValue';
import { useGlobalFilter } from '@libero/hooks/useGlobalFilter';
import type { SelectOption, SelectValue, SelectValues } from '@libero/types/Select';
import type { Props as SelectProps } from '@libero/ui-framework/Select/Select.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { omit, toLower } from 'lodash';
import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

const RENDER_ALL_ITEMS_APIS = [
  roleApi.name,
  entityTypeApi.name,
  organisationApi.name,
  externalEndpointApi.name,
  ticketTypeApi.name,
  ticketStatusApi.name,
  ticketSubjectApi.name,
  contractApi.name,
  leadTimeApi.name,
  causeApi.name,
  themeApi.name,
  powerScheduleApi.name,
  dimCalendarApi.name,
  productApi.name,
  viewApi.name,
];

const THEME_APIS = [
  containerApi.name,
  entityTypeApi.name,
  roleApi.name,
  ticketApi.name,
  ticketStatusApi.name,
  ticketSubjectApi.name,
  userApi.name,
  causeApi.name,
  contractApi.name,
];

export interface Props extends SelectProps {
  restrictedIds?: (string | number)[];
  query?: ScoutQuery;
  resourceApi: Required<Pick<ResourceApi<Resource>, 'name' | 'scout'>>;
  mapOption?: (resource: Resource) => SelectOption;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResource?: (resource?: any) => void;
}

const props = withDefaults(defineProps<Props>(), {
  mode: undefined,
  size: undefined,
  width: undefined,
  placeholder: undefined,
  error: undefined,
  query: undefined,
  restrictedIds: () => [],
  mapOption: undefined,
  onResource: undefined,
});

const { t } = useI18n();
const { globalFilter } = useGlobalFilter();

const searchTerm = ref<string>();
const debouncedSearchTerm = useDebouncedValue(searchTerm);

const values = computed(
  () => (Array.isArray(props.value) ? props.value : [props.value]).filter(Boolean) as SelectValue[],
);

const restrictedIds = toRef(props, 'restrictedIds');
const query = toRef(props, 'query');

const renderAllItems = computed(() => RENDER_ALL_ITEMS_APIS.includes(props.resourceApi.name));

const filterThemes = computed(() => {
  if (!THEME_APIS.includes(props.resourceApi.name)) return;
  if (query.value?.filter?.themes) return query.value.filter.themes;
  return globalFilter.value.themes;
});

const { data, isFetching, refetch } = useQuery({
  queryKey: [
    `${props.resourceApi.name}.index`,
    'scout',
    values,
    restrictedIds,
    globalFilter,
    query,
    debouncedSearchTerm,
  ],
  queryFn: async () => {
    const queryOptions = {
      returnAllItems: renderAllItems.value || undefined,
      selectedIds: values.value,
      restrictedIds: restrictedIds.value,
      ...query.value,
      filter: {
        ...globalFilter.value,
        ...query.value?.filter,
        themes: filterThemes.value,
        scout: debouncedSearchTerm.value || undefined,
      },
    };

    if (props.mapOption) {
      return props.resourceApi.scout.index(queryOptions).then((resources) => {
        if (props.mapOption) {
          return resources.map(props.mapOption);
        }

        return [];
      });
    }

    return props.resourceApi.scout.options(queryOptions);
  },
  placeholderData: keepPreviousData,
});

const debounceRefetch = useDebounce(refetch, 400);

const handleSearch = (value: string) => {
  searchTerm.value = value;
  debounceRefetch();
};

const resourceName = computed(() => t(`${props.resourceApi.name}.${props.resourceApi.name}`));
const resourcesName = computed(() => t(`${props.resourceApi.name}.${props.resourceApi.name}s`));
const isMultiple = computed(() => props.mode === 'multiple');

const isEmpty = computed(() => !isFetching.value && !!data.value && !!debouncedSearchTerm.value);

const defaultPlaceholder = computed(() => {
  if (!renderAllItems.value) {
    return t('search-resources', { resources: resourcesName.value });
  }

  if (data.value?.length === 0) {
    return t('search-empty-resources', { resources: toLower(resourcesName.value) });
  }

  if (isMultiple.value) {
    return t('choose-resources', { resources: toLower(resourcesName.value) });
  }

  return t('choose-resource', { resource: toLower(resourceName.value) });
});

const handleUpdate = (value: SelectValues) => {
  props.onUpdate?.(value);
  props.onResource?.(data.value?.find((item) => item.value === value)?.item);
};
</script>

<template>
  <Select
    v-bind="
      omit(props, [
        'placeholder',
        'filterOption',
        'searchValue',
        'isLoading',
        'isEmpty',
        'hasSearchIcon',
        'options',
        'options',
        'onSearch',
        'onUpdate',
        'resourceApi',
        'restrictedIds',
        'query',
      ])
    "
    :placeholder="placeholder || defaultPlaceholder"
    :filterOption="false"
    :searchValue="searchTerm"
    :isLoading="isFetching"
    :isEmpty="isEmpty"
    :hasSearchIcon="!renderAllItems"
    :options="[...(data || []), ...(options || [])]"
    :onSearch="handleSearch"
    :onUpdate="handleUpdate"
  />
</template>
