import { type Ref, ref, watch } from 'vue';

import { useDebounce } from './useDebounce';

export const useDebouncedValue = <T>(refValue: Ref<T>, timeout = 300): Ref<T> => {
  const debouncedValue = ref(refValue.value) as Ref<T>;

  const setDebouncedValue = useDebounce((newValue: T) => {
    debouncedValue.value = newValue;
  }, timeout);

  watch(refValue, () => {
    setDebouncedValue(refValue.value);
  });

  return debouncedValue;
};
