import { generateResourceScoutApi } from '@libero/api-client/scout/api';

import type { ExternalEndpoint } from './types';

export const externalEndpointApi = {
  name: 'external-endpoint',
  scout: generateResourceScoutApi<ExternalEndpoint>('external-endpoint', (item) => ({
    label: item.name,
    value: item.id,
  })),
};
