import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { Color } from '@libero/types/Color';
import { jsonApi } from '@libero/utilities/api-client';
import { getThemeIcon } from '@libero/utilities/theme';

import type { EmailActivateRequest, Theme } from './types';

const theme = jsonApi('theme');

const index = (): Promise<Theme[]> => {
  return theme.get();
};

const emailActivate =
  (id: number) =>
  (data: EmailActivateRequest): Promise<unknown> => {
    return theme.post(`${id}/request-theme-information`, data);
  };

export const themeApi = {
  name: 'theme',
  index,
  emailActivate,
  scout: generateResourceScoutApi<Theme>('theme', (item) => ({
    icon: getThemeIcon(item.key),
    iconColor: Color.primary800,
    label: item.name,
    value: item.id,
  })),
};
