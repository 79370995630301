<script setup lang="ts">
import {
  ArrowDownOnSquareIcon,
  ArrowDownOnSquareStackIcon,
  ArrowUturnLeftIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/vue/24/solid';
import { EyeIcon } from '@heroicons/vue/24/solid';
import { PaginationQueryParams } from '@libero/api-client/types/pagination';
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import { viewApi } from '@libero/api-client/view/api';
import Create from '@libero/cityview-overview/modules/view/views/modals/Create.vue';
import Edit from '@libero/cityview-overview/modules/view/views/modals/Edit.vue';
import { UseResourceView } from '@libero/hooks/resource/useResourceView';
import { useDebouncedValue } from '@libero/hooks/useDebouncedValue';
import { useInput } from '@libero/hooks/useInput';
import { useQueryParams } from '@libero/hooks/useQueryParams';
import DestroyModal from '@libero/organisms/DestroyModal/DestroyModal.vue';
import Button from '@libero/ui-framework/Button/Button.vue';
import ButtonGroup from '@libero/ui-framework/ButtonGroup/ButtonGroup.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import ContentLoading from '@libero/ui-framework/ContentLoading/ContentLoading.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import DropdownFooter from '@libero/ui-framework/Dropdown/DropdownFooter.vue';
import DropdownHeader from '@libero/ui-framework/Dropdown/DropdownHeader.vue';
import DropdownItem from '@libero/ui-framework/Dropdown/DropdownItem.vue';
import Input from '@libero/ui-framework/Input/Input.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { map } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  resourceApi: Pick<ResourceApi, 'name'>;
  resourceView: UseResourceView;
}

const props = defineProps<Props>();

const { t } = useI18n();
const queryClient = useQueryClient();
const [searchTerm, registerSearchTerm, updateSearchTerm] = useInput('search-term');
const { params } = useQueryParams<PaginationQueryParams>();

const debouncedSearchTerm = useDebouncedValue(searchTerm);

const id = computed(() => props.resourceView.view?.id);

const { data: views, isLoading } = useQuery({
  queryKey: ['view.index', props.resourceApi.name, debouncedSearchTerm],
  queryFn: () =>
    viewApi.index({
      filter: {
        scout: debouncedSearchTerm.value || undefined,
      },
    }),
});

const { mutate: handleUpdate } = useMutation({
  mutationFn: () => {
    if (!id.value) return Promise.resolve(null);

    return viewApi.update(id.value)({
      columns: map(props.resourceView.columns, 'key'),
      query: params.value,
    });
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: [`view.index`] });
    queryClient.invalidateQueries({ queryKey: [`${props.resourceApi.name}.view.show`, id.value] });

    message.success(t('saved'));
  },
});
</script>

<template>
  <ButtonGroup>
    <Dropdown shouldStayOpen :onClose="() => updateSearchTerm(null)">
      <template #trigger>
        <Button size="sm" appearance="select">
          <template #icon>
            <EyeIcon />
          </template>

          <Typography shouldInherit truncated maxWidth="20rem">
            {{ resourceView.view?.name }}
          </Typography>

          <template #icon-right>
            <ChevronDownIcon />
          </template>
        </Button>
      </template>

      <template #content>
        <DropdownHeader>
          <Input
            size="sm"
            :placeholder="t('search.search')"
            isClearable
            v-bind="registerSearchTerm()"
          >
            <template #icon>
              <MagnifyingGlassIcon />
            </template>
          </Input>
        </DropdownHeader>

        <DropdownBody>
          <DropdownAction
            v-for="item in views?.data"
            :key="item.id"
            :isActive="!resourceView.hasChanges && item.id === resourceView.view?.id"
            :onClick="() => resourceView.handleSelectView(item.id)"
          >
            <Cluster flex="1" alignItems="center" :gap="0.5">
              <Cluster flex="1" alignItems="center" justifyContent="space-between" :gap="1">
                <Typography shouldInherit truncated maxWidth="28rem">
                  {{ item.name }}
                </Typography>
              </Cluster>
            </Cluster>
          </DropdownAction>

          <template v-if="!views?.data?.length">
            <ContentLoading v-if="isLoading" spacing="sm" />

            <DropdownItem v-else>
              {{ t('resource.no-views') }}
            </DropdownItem>
          </template>
        </DropdownBody>
      </template>
    </Dropdown>

    <Dropdown
      v-if="resourceView.view && (!resourceView.view?.is_default || resourceView.hasChanges)"
    >
      <template #trigger>
        <Button size="sm" appearance="select">
          <template #icon>
            <EllipsisVerticalIcon />
          </template>
        </Button>
      </template>

      <template #content>
        <DropdownBody v-if="resourceView.hasChanges">
          <DropdownAction :onClick="resourceView.handleReset">
            <ArrowUturnLeftIcon class="dropdown-action-icon" />
            {{ t('resource.reset') }}
          </DropdownAction>
        </DropdownBody>

        <DropdownBody>
          <Create
            v-if="resourceView.view.is_default"
            v-slot="{ openModal }"
            :resourceApi="resourceApi"
            :columns="resourceView.columns"
            :onDone="resourceView.handleSelectView"
          >
            <DropdownAction :onClick="openModal">
              <ArrowDownOnSquareIcon class="dropdown-action-icon" />
              {{ t('save') }}
            </DropdownAction>
          </Create>

          <template v-else>
            <DropdownAction :onClick="handleUpdate">
              <ArrowDownOnSquareIcon class="dropdown-action-icon" />
              {{ t('save') }}
            </DropdownAction>

            <Create
              v-slot="{ openModal }"
              :resourceApi="resourceApi"
              :columns="resourceView.columns"
              :onDone="resourceView.handleSelectView"
            >
              <DropdownAction :onClick="openModal">
                <ArrowDownOnSquareStackIcon class="dropdown-action-icon" />
                {{ t('save-as') }}
              </DropdownAction>
            </Create>

            <Edit v-slot="{ openModal }" :view="resourceView.view" :resourceApi="resourceApi">
              <DropdownAction :onClick="openModal">
                <PencilSquareIcon class="dropdown-action-icon" />
                {{ t('view.change-name') }}
              </DropdownAction>
            </Edit>
          </template>
        </DropdownBody>

        <DropdownFooter v-if="resourceView.view && !resourceView.view.is_default">
          <DestroyModal
            v-slot="{ openModal }"
            :subject="resourceView.view.name"
            :item="resourceView.view"
            :invalidateKeys="[[`${resourceApi.name}.view.tabs`]]"
            :resourceApi="viewApi"
            :onSuccess="resourceView.handleClearView"
          >
            <DropdownAction color="error" :onClick="openModal">
              <TrashIcon class="dropdown-action-icon" />
              {{ t('destroy') }}
            </DropdownAction>
          </DestroyModal>
        </DropdownFooter>
      </template>
    </Dropdown>
  </ButtonGroup>
</template>
