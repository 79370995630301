<script setup lang="ts">
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { classNames } from '@libero/utilities/class-names';
import { RadioGroup } from 'ant-design-vue';
import { RadioGroupChildOption } from 'ant-design-vue/lib/radio/Group';
import { onMounted } from 'vue';
import { onUnmounted } from 'vue';

interface Props {
  id: string;
  name: string;
  value: boolean | string | null;
  error?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  isHorizontal?: boolean;
  hasButtons?: boolean;
  disabled?: boolean;
  options?: RadioGroupChildOption[];
  onUpdate: (value: string | null) => void;
  onMount?: (onFocus?: () => void, shouldFocus?: boolean) => void;
  onUnmount?: () => void;
}

const props = withDefaults(defineProps<Props>(), {
  error: undefined,
  size: 'md',
  options: () => [],
  onMount: undefined,
  onUnmount: undefined,
});

onMounted(() => {
  props.onMount?.();
});

onUnmounted(() => {
  props.onUnmount?.();
});
</script>

<template>
  <Stack :gap="0">
    <RadioGroup
      :id="id"
      :name="name"
      :class="classNames({ size, isHorizontal, hasButtons })"
      :value="value"
      :disabled="disabled"
      :optionType="hasButtons ? 'button' : 'default'"
      :options="options"
      :onUpdate:value="onUpdate"
    />

    <InputError :message="error" />
  </Stack>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/RadioGroup/RadioGroup.scss';
</style>
