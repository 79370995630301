<script lang="ts" setup>
import { ResourceApi } from '@libero/api-client/types/resource-api';
import ResourceFilter from '@libero/cityview-overview/modules/resource/segments/ResourceFilter/ResourceFilter.vue';
import Drawer from '@libero/ui-framework/Drawer/Drawer.vue';
import DrawerSection from '@libero/ui-framework/Drawer/DrawerSection.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  resourceApi: Pick<ResourceApi, 'name' | 'view'>;
}

defineProps<Props>();

const { t } = useI18n();

const isOpen = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <slot :isOpen="isOpen" :openDrawer="toggleOpen" />

  <Drawer :isOpen="isOpen" :onClose="toggleOpen" isPersistent>
    <DrawerSection isHeading hasNoSpacingBottom>
      <Stack :gap="0.5">
        <Typography tag="h3" type="heading" mb="100" bold>
          {{ t('resource.filters') }}
        </Typography>
      </Stack>
    </DrawerSection>

    <DrawerSection spacing="sm" flex="1" isScrollable hasNoSpacing>
      <ResourceFilter :resourceApi="resourceApi" />
    </DrawerSection>
  </Drawer>
</template>
