<script setup lang="ts">
import type { ResourceApi } from '@libero/api-client/types/resource-api';
import ContentLoading from '@libero/ui-framework/ContentLoading/ContentLoading.vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';

import ResourceFilterColumns from './ResourceFilterColumns.vue';

interface Props {
  resourceApi: Pick<ResourceApi, 'name' | 'view'>;
}

const props = defineProps<Props>();

const { data, isLoading } = useQuery({
  queryKey: [`${props.resourceApi.name}.view.available-columns`],
  queryFn: () => props.resourceApi.view?.availableColumns(),
  placeholderData: keepPreviousData,
});

const columns = computed(() => data.value?.filter((column) => column.has_filter));
</script>

<template>
  <ResourceFilterColumns :columns="columns" />

  <ContentLoading v-if="isLoading" />
</template>
