import { Activity } from '@libero/api-client/activity/types';
import { Map, MapQuery, MapTickets } from '@libero/api-client/map/types';
import { noteRelationApi } from '@libero/api-client/note/relation';
import { generateResourceScoutApi } from '@libero/api-client/scout/api';
import { ticketRelationApi } from '@libero/api-client/ticket/relation';
import type { PaginationResource } from '@libero/api-client/types/pagination';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { generateResourceViewApi } from '@libero/api-client/view/api';
import type { Count, CountQuery } from '@libero/types/Count';
import { jsonApi } from '@libero/utilities/api-client';
import { getContainerAttributeValue } from '@libero/utilities/attributes';
import QueryString from 'qs';

import { containerRelationApi } from './relation';
import type {
  Container,
  ContainerLocation,
  ReplicateContainerRequest,
  StoreContainerRequest,
  ThemeCount,
  UpdateContainerRequest,
} from './types';

const container = jsonApi('container');

const index = (query?: PaginationQuery): Promise<PaginationResource<Container>> => {
  return container.post('index', query);
};

const indexIds = (query?: PaginationQuery): Promise<string[]> => {
  return container.post('id', query);
};

const exp = (query?: PaginationQuery): Promise<void> => {
  return container.post('export', query);
};

const mapBlank = (query?: MapQuery): Promise<Map> => {
  return container.get('map-blank', {
    searchParams: QueryString.stringify(query),
  });
};

const map = (query?: MapQuery): Promise<Map> => {
  return container.get('map', {
    searchParams: QueryString.stringify(query),
  });
};

const mapTickets = (id: string | number, query?: MapQuery): Promise<MapTickets> => {
  return container.get(`${id}/map/tickets`, {
    searchParams: QueryString.stringify(query),
  });
};

const mapItem = (query?: PaginationQuery): Promise<ContainerLocation> => {
  return container.get(`map-item`, {
    searchParams: QueryString.stringify(query),
  });
};

const show = (id: string | number): Promise<Container> => {
  return container.get(id.toString());
};

const store = (data: StoreContainerRequest): Promise<Container> => {
  return container.post(undefined, data);
};

const update =
  (id: string | number) =>
  (data: UpdateContainerRequest): Promise<unknown> => {
    return container.put(id.toString(), data);
  };

const replicate =
  (id: string | number) =>
  (data: ReplicateContainerRequest): Promise<Container> => {
    return container.post(`${id}/replicate`, data);
  };

const attachContainers =
  (id: string | number) =>
  (ids: (string | number)[]): Promise<Container> => {
    return container.put(`${id}/container/attach`, { containers: ids });
  };

const attachTickets =
  (id: string | number) =>
  (ids: (string | number)[]): Promise<Container> => {
    return container.put(`${id}/ticket/attach`, { tickets: ids });
  };

const favorite = (id: string | number): Promise<unknown> => {
  return container.post(`${id}/favorite`);
};

const activity = (id: string | number): Promise<Activity[]> => {
  return container.get(`${id}/activity`);
};

const location = (id: string | number): Promise<ContainerLocation> => {
  return container.get(`${id}/location`);
};

const themeCount = (query: CountQuery): Promise<ThemeCount[]> => {
  return container.get('theme/count', {
    searchParams: QueryString.stringify(query),
  });
};

const count = (query: CountQuery): Promise<Count> => {
  return container.get('all/count', {
    searchParams: QueryString.stringify(query),
  });
};

export const containerApi = {
  name: 'container',
  index,
  indexIds,
  export: exp,
  mapBlank,
  mapTickets,
  map,
  mapItem,
  show,
  store,
  update,
  attachContainers,
  attachTickets,
  replicate,
  favorite,
  activity,
  location,
  themeCount,
  count,
  view: generateResourceViewApi('container'),
  scout: generateResourceScoutApi<Container>(
    'container',
    (item) => {
      const publicSpaceName = getContainerAttributeValue(item, 'public-space-name', true);
      const city = getContainerAttributeValue(item, 'city', true);

      return {
        label: [city, publicSpaceName, item.name].filter(Boolean).join(' '),
        value: item.id,
      };
    },
    {
      include: 'entities.attributes',
      attributeTypes: 'public-space-name,city',
    },
  ),
  relation: {
    container: containerRelationApi('container'),
    ticket: ticketRelationApi('container'),
    note: noteRelationApi('container'),
  },
};
