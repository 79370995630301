import { mergeOverwrite } from '@libero/utilities/object';
import { isEqual, mapValues } from 'lodash';
import { type ParsedQs } from 'qs';
import { computed, Ref } from 'vue';
import { type LocationQueryRaw, useRoute, useRouter } from 'vue-router';

interface UseQueryParams<QueryString> {
  params: Ref<QueryString>;
  appendParams: (queryParams: QueryString, shouldReplace?: boolean) => void;
  overwriteParams: (queryParams: QueryString, shouldReplace?: boolean) => void;
}

export const useQueryParams = <QueryString = ParsedQs>(): UseQueryParams<QueryString> => {
  const router = useRouter();
  const route = useRoute();

  const params = computed(() => route.query as QueryString);

  const appendParams = (queryParams: QueryString, shouldReplace = false) => {
    const mergedQueryParams = mergeOverwrite(params.value, queryParams) as LocationQueryRaw;

    if (isEqual(mergedQueryParams, params.value)) return;

    if (shouldReplace) {
      router.replace({ query: mergedQueryParams });
    } else {
      router.push({ query: mergedQueryParams });
    }
  };

  const overwriteParams = (queryParams: QueryString, shouldReplace = false) => {
    const mergedQueryParams = {
      ...mapValues(params.value as LocationQueryRaw, () => undefined),
      ...queryParams,
    } as LocationQueryRaw;

    if (isEqual(mergedQueryParams, params.value)) return;

    if (shouldReplace) {
      router.replace({ query: mergedQueryParams });
    } else {
      router.push({ query: mergedQueryParams });
    }
  };

  return {
    params,
    appendParams,
    overwriteParams,
  };
};
