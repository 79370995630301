<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { Color } from '@libero/types/Color';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import { Collapse } from 'ant-design-vue';
import type { Key } from 'ant-design-vue/lib/_util/type';
import { ref } from 'vue';

interface Props {
  isNested?: boolean;
}

defineProps<Props>();

const activeKeys = ref<Key | Key[]>([]);

const setActiveKeys = (keys: Key | Key[]) => {
  activeKeys.value = keys;
};
</script>

<template>
  <Collapse :activeKey="activeKeys" :onUpdate:activeKey="setActiveKeys">
    <slot />

    <template #expandIcon="props">
      <Icon
        size="xl"
        :isRotated="props?.isActive"
        :color="isNested ? Color.gray700 : Color.gray500"
      >
        <ChevronDownIcon />
      </Icon>
    </template>
  </Collapse>
</template>

<style lang="scss" scoped>
@import '@libero/ui-framework/Collapse/Collapse.scss';
</style>
